/**************************************

calendar

**************************************/

.main_plan .fair_all .calendar_inner {
	padding: 0;
  margin-bottom: 0;
  position: relative;
}

.main_plan .ifrm_container {
	height: 320px;
  margin-bottom: 0;
  position: relative;
  overflow-x: hidden;
}

.fair_calendar{
  margin:0;
  padding:10% 5% 5%;
}

.fair_calendar h4 {
  font-size: 44px;
  text-align: center;
  line-height: .7;
}


body.day{
  max-width: 350px;
  margin: 0 auto;
}

.section_list.day {
    width: 90%;
    height: 260px;
    margin: 0 5%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.section_list.day ul.plan {
    height: auto;
}

.section_list.fair_calendar .catch{
  font-size:14px;
  text-align:center;
  margin-bottom:10px;
}

.caution{
  font-size: 11px;
  color: #fff;
  text-align: center;
  padding: 10px 0px;
  margin:-5px auto 6%;
  background: #9c998f;
  position: relative;
}

.caution::after {
		display: block;
    content: "";
    position: absolute;
    bottom: -15px;
		left: 50%;
    width: 0;
    height: 0;
    margin-left: -7px;
    border: 7px solid transparent;
    border-left: 10px solid #9c998f;
		-webkit-transform: rotate(90deg);
  	transform: rotate(90deg);
}

.ifrm_container{
  height:320px;
	margin-bottom: 28px;
  position: relative;
  overflow-x: hidden;
  border: 1px solid #403223;
}

.ifrm_container iframe{
  border: none;
  width: 100%!important;
  height: 100%;
  position: absolute;
  left: 0;
  top:0;
}

.section_detail.btn_list{
  background:none;
  margin:0;
  padding:0;
}

.section_detail.btn_list .btn a{
  padding:19px 0;
}

/* Fair_detail
---------------------------------------------------*/

.section_fair_main {
	width:100%;
	background:#fff;
	padding:0 5% 5%;
}

.section_fair_main.detail,
.section_fair_main.detail .inner,
.section_fair_main.detail .unit,
.section_fair_main.detail .unit div,
.section_fair_main.detail .unit ul {
  opacity:1.0;
  transform:none;
}

.section_fair_main .info_box {
  text-align:center;
}

.section_fair_main .ficon {
  text-align: center;
}

.section_fair_main .date {
  font-size: 13px;
  margin-bottom: 10px;
}

.section_fair_main .title {
  font-size: 18px;
  margin-bottom:5px;
  font-weight: 700;
}

.section_fair_main .icons {
  text-align:center;
  margin-top:10px;
}

.section_list .icons {
  height:20px;
  margin-bottom:5px;
  overflow:hidden;
}

.section_rec .icons {
  text-align:center;
  margin-bottom:10px;
}

.section_fair_main .icons li,
.section_rec .icons li,
.section_list .icons li{
    padding: 2px 10px 4px;
    color: #fff;
    text-align: center;
    line-height: 1.1em;
    font-size: 0.85em;
    margin-right: 3px!important;
    display: inline-block;
    width:auto!important;
    float:none!important;
  margin-bottom: 2px;
}

.section_fair_main .txt {
  margin-bottom:10px!important;
  font-size:15px;
}

.section_fair_main .section_inner {
  margin-bottom: 36px;
}

.section_fair_main .photo {
  overflow: hidden;
  width: 100%;
  margin:20px 0;
}

.section_fair_main .photo img {
  width: 100%;
}

.section_fair_main .info {
}
.ficon {
    margin-top: 5px;
    margin-bottom: 10px;
    /*display: block;*/
    text-align: left;
}
.section_detail .ficon {
    text-align: center;
}
.ficon .special{
    line-height: 1em;
    font-size: 10px;
    color: #fff;
    display: inline;
    white-space: nowrap;
    padding: 2px 10px 3px;
    margin: 0 3px 2px 0;
}

.ficon .special01 {
    background: #c63504;
}
.ficon .special02 {
    background: #c79600;
}
.ficon .special03 {
    background: #c9a389;
}
.ficon .special04 {
    background: #95a977;
}
.ficon .special05 {
    background: #d7a06a;
}

.section_fair_main .data {
  width: 100%;
  margin-bottom: 20px;
}

.section_fair_main .data.date_box {
  padding: 10% 0;
}

.section_fair_main .data.date_box .tit {
  font-size: 16px;
  text-align: center;
  padding-bottom: 3%;
  border-bottom: 1px solid #403223;
  margin-bottom: 5%;
  line-height: 1.0;
}

.section_fair_main .data.date_box th,
.section_fair_main .data.date_box td {
  border: none;
  font-size: 12px;
}

.section_fair_main .data.date_box td {
  text-align: left;
}

.section_fair_main .data.date_box th {
  width:30%;
  background: #403223;
  color: #403223;
  border-right: none;
  padding: 10px 6px;
}

.section_fair_main .data.date_box tbody tr {
  border: none;
  border-bottom: 1px rgba(0,0,0,0.2) solid;
}

.section_fair_main .data.date_box tbody tr:first-child {
  border-top: 1px rgba(0,0,0,0.2) solid;
}

.section_fair_main .data table {
  margin-bottom: 8px;
  width: 100%;

  border-collapse: collapse;
}
.section_fair_main .data tbody tr {
  border: 1px #403223 solid;
}
.section_fair_main .data th,
.section_fair_main .data td {
  font-size: 13px;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px #403223 solid;
  border-right: 1px #403223 solid;
}
.section_fair_main .data th {
  font-weight: bold;
  padding: 5px 4px;
  color: #fff;
  background: #403223;
  border-right:1px solid #403223;
  letter-spacing: 1px;
}

.section_fair_main .data th:nth-child(1) {width:30%;}
.section_fair_main .data th:nth-child(2) {width:15%;}
.section_fair_main .data th:nth-child(3) {width:55%;}

.section_fair_main .data th:nth-child(4) {
  border-right:none;
}

.section_fair_main .data .fairtime {
  text-align: center;
}

.section_fair_main .data td {
  padding: 5px 4px;
  vertical-align: middle;
}
.section_fair_main .data td:last-child {
  padding: 10px 8px;
}
.section_fair_main .data .note {
  width: 35%;
}
.section_fair_main .data .ex dt,
.section_fair_main .data .ex dd {
  font-size: 12px;
  font-weight: normal;

  float: left;
}
.section_fair_main .data .ex dt:after {
  content: ": ";
}
.section_fair_main .data .ex dd {
  margin-right: 2em;
}
.section_fair_main .data .reserve_time a {
	width:100%;
}

.section_fair_main .data span{
  display:block;
}

.section_fair_main .txtbox {
  margin-top:30px;
  margin-bottom: 25px;
}

.section_fair_main .txtbox .caption {
  border-bottom:1px solid #403223;
  padding-bottom:40px;
  margin-bottom:40px;
}

.section_fair_main .txtbox .value_box {
  padding:10px;
  background:#faf6f1;
  margin-top:20px;
}

.section_fair_main .txtbox .value_box .tit {
	border-top: 1px solid #403223;
	border-bottom: 1px solid #403223!important;
	color:#403223 !important;
	font-size:16px;
	text-align:center;
	padding:4px 0 5px;
	margin:0px 0 5px;
  border-bottom:none;
}

.text03 {
  text-align: left;
  padding:5px 10px 10px;
}

.section_fair_main .txtbox .value_box span {
	color:#ffff6e;
	padding-left:3px;
}

.fair_box{
	width:980px;
	margin:25px auto 0;
	z-index:999;
	position:relative;
}

ul.icon_info{
	display:block;
	margin:0;
}

ul.icon_info li{
	float:left;
	display:block;
	margin:0 0px 3px 0 ;
}

.section_fair_btns .btn05 a {

    display: block;
    font-size: 1.2em;
    text-align: center;
    background: #ceb264;
    box-sizing: border-box;
    text-decoration: none;
    color: #fff;
    position: relative;
    width: 520px;
    padding: 14px 0 14px;
    margin: 0 auto 20px;
    letter-spacing: 1px;
}

.section_fair_btns .btn05 a:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    right: 5px;
    width: 0;
    height: 0;
    margin: -3px 0 0 0;
    border: 3px solid transparent;
    border-left: 5px solid #fff;
}

.section_fair_btns span.txt_sub {
    display: block;
    font-size: 14px;
    line-height: 14px;
}

iframe {
  width: 100% !important;
}

/*.container img {
  height: auto!important;
  max-width: 100%!important;
}*/

.section_fair_main .form_menu {
}

.section_fair_main .form_menu .reserve_time{
  border-top:1px dotted #403223;
  border-bottom:1px dotted #403223;
  padding:10px 0;
  margin-bottom:20px;
  text-align:center;
  font-size:16px;
}

.section_fair_main .form_menu .reserve_time .tit_form{
  margin-bottom:5px;
}

.section_fair_main .form_menu .reserve_time .select_box{
  width:100%;
}

.section_fair_main .form_menu .reserve_time .select_box select {
  height: 2.4em;
  padding: 0;
}

.section_fair_main .form_menu .btn_reserve{
  width:100%;
  margin:0 auto;
  position:relative;
}

.section_fair_main .form_menu .btn_reserve::after{
	display: block;
	content: "";
	position: absolute;
	top: 50%;
	right: 10px;
	width: 0;
	height: 0;
	margin-top: -4px;
	border: 3px solid transparent;
	border-left: 5px solid #FFF;
	transition-duration:200ms;
}

.section_fair_main .form_menu .btn_reserve input{
  width:100%;
  padding:16px 0;
   -webkit-appearance: none;
   background:#84b8b5;
   border:none;
   color:#FFF;
   font-size:16px;
   font-weight: 700;
}

.reserve_time.btn_time a {
  display: block;
  font-size: 12px;
  text-align: center;
  background: #beab90;
  box-sizing: border-box;
  text-decoration: none;
  color: #FFF;
  position: relative;
  margin: 0 auto;
  letter-spacing: 1px;
  line-height: 30px;
}

.reserve_time a:after {
	display: block;
	content: "";
	position: absolute;
	top: 50%;
  right: 2px;
	width: 0;
	height: 0;
  margin: -3px 0 0 0;
	border: 3px solid transparent;
	border-left: 5px solid #FFF;
}

.section_plan_main.fair {
    background: none;
    padding: 0px;
    box-shadow: none;
}

.section_plan_main.fair .btn {
 margin:0 15px 10px;
}

.section_plan_main.fair .btn a{
  line-height:40px;
}

.section_fair_btns .btn.cansel{
  margin:0 15px 20px;
}

.section_fair_btns .btn.cansel a{
  line-height:1.4;
  padding:10px 0;
  display:block;
  background:none;
	border-top: 1px solid #403223;
	border-bottom: 1px solid #403223;
	color: #403223;
  position: relative;
}

.section_fair_btns .btn.cansel a::after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    width: 0;
    height: 0;
    margin: -3px 0 0 0;
    border: 3px solid transparent;
    border-left: 5px solid #403223;
}

h3.tit_category {
	font-size:14px;
	text-align:left;
  margin-bottom:15px;
	padding:10px 5px 10px 15px;
	background:#403223;
	margin-bottom:15px;
  letter-spacing: 1.0px;
	color: #fff;
}

/************************************************

	Clear

************************************************/

.calender {
  zoom: 1;
}

.calender:after {
  content: '';
  display: block;
  clear: both;
  overflow: hidden;
}
